import React, { useEffect } from "react";
import "../css/NavBar.css";
import { ReactComponent as LightBulbOn } from "../icons/light-bulb-on.svg";
import { ReactComponent as LightBulbOff } from "../icons/light-bulb-off.svg";
import { ReactComponent as MeDrawing } from "../pages/img/me_drawing.svg";
import { ReactComponent as MobileMenuIcon } from "./img/Menu.svg";
import "../css/App.css";

function NavBar(props) {
  const [darkmode, setDarkmode] = React.useState(
    !document.cookie.includes("daniel_light_on=true")
  );
  const [navHeight, setNavHeight] = React.useState({ transition: "0s" });
  const [menuHeight, setMenuHeight] = React.useState({ transition: "0s" });
  const [menuVisible, setMenuVisible] = React.useState(false);
  useEffect(() => {
    updateModeHarsh();

    setNavHeight({
      marginBottom: document.getElementById("nav").clientHeight + "px",
    });
    setMenuHeight({
      marginBottom: document.getElementById("menu").clientHeight + "px",
    });
    console.log(menuHeight.marginBottom);
  }, [darkmode]);
  // react effect to set reoccuring function to print to console isOn every second
  return (
    <div>
      <div
        id="menu"
        className="MobileMenu"
        style={{
          top: menuVisible ? 0 : "-" + menuHeight.marginBottom,
          overflow: "hidden",
          //display: menuVisible ? "flex" : "none",
        }}
      >
        <div id="offset_navbar" style={navHeight}></div>
        <a className="MobileMenuItem" href="/">
          Home
        </a>
        <a className="MobileMenuItem" href="blog">
          Blog
        </a>
        <a className="MobileMenuItem" href="about">
          About
        </a>
        <a className="MobileMenuItem" href="projects">
          Projects
        </a>
        <a className="MobileMenuItem" href="contact">
          Contact
        </a>
        <a className="MobileMenuItem" href="https://github.com/danieldb">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              id="github"
              className="GithubIcon"
              d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
            />
          </svg>
        </a>
      </div>
      <div className="NavBar" id="nav">
        <h1
          className="NavBarTitle"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          danieldb
        </h1>
        {/*eslint-disable-next-line*/}
        <a className="NavBarLocation">
          {window.location.pathname.split("/").join(" / ")}
        </a>
        <button className="MobileMenuButton">
          <MobileMenuIcon
            className="mode_svg"
            style={{
              gridRow: 1,
              height: 48,
              width: 48,
              transform: "translateY(8px)",
              paddingInline: 8,
            }}
            onClick={() => {
              setMenuVisible(!menuVisible);
            }}
          ></MobileMenuIcon>
        </button>
        <div className="NavBarItems">
          <a className="NavBarItem" href="/">
            Home
          </a>
          <a className="NavBarItem" href="/blog">
            Blog
          </a>
          <a className="NavBarItem" href="/about">
            About
          </a>
          <a className="NavBarItem" href="/projects">
            Projects
          </a>
          <a className="NavBarItem" href="https://github.com/danieldb">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="github"
                className="GithubIcon"
                d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
              />
            </svg>
          </a>
          <button
            className="NavBarItem"
            onClick={() => {
              var mfw = new CustomEvent("makefirework", {
                detail: 0,
                bubbles: false, // <-- try setting to false
                cancelable: true,
              });
              document.dispatchEvent(mfw);
            }}
          >
            <span role={"img"} aria-labelledby="firework">
              🎇
            </span>
          </button>
        </div>
        <a
          className="NavBarItem"
          href="contact"
          style={{ padding: 0, marginBlock: 8 }}
        >
          <MeDrawing
            className="mode_svg"
            style={{
              gridRow: 1,
              height: 48,
              width: 48,
              transform: "translateY(8px)",
              paddingInline: 8,
            }}
          />
        </a>
        <div id="lightbulb" className="LightDarkToggle">
          <LightBulbOff
            id="lightbulb_off"
            onClick={() => {
              toggleMode();
            }}
            className="Lightbulb"
            style={{ display: darkmode ? "block" : "none" }}
          />
          <LightBulbOn
            id="lightbulb_on"
            onClick={() => {
              toggleMode();
            }}
            className="Lightbulb"
            style={{ display: !darkmode ? "block" : "none" }}
          />
        </div>
      </div>
      <div id="offset_navbar" style={navHeight}></div>
    </div>
  );

  function updateModeHarsh() {
    darkmode
      ? document.body.classList.add("darkmode")
      : document.body.classList.remove("darkmode");
    document.body.style.backgroundColor = darkmode ? "#000" : "#eee"; // doesn't work in css ?? weird
  }

  function updateMode() {
    document.body.style.transition = "0.5s ease";
    for (let e of document.querySelectorAll("*"))
      e.style.transition = "0.5s ease";
    darkmode
      ? document.body.classList.add("darkmode")
      : document.body.classList.remove("darkmode");
    document.body.style.backgroundColor = darkmode ? "#000" : "#eee"; // doesn't work in css ?? weird
  }

  function toggleMode() {
    setDarkmode(!darkmode);
    setCookie("daniel_light_on", darkmode, 365);
    updateMode();
  }

  function setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";";
  }
}

export default NavBar;
