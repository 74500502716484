import React from "react";
import Info from "../../components/Info";
import "../../css/ProjectPage.css";
import masked from "./img/fourier-masked.png";
import original from "./img/fourier-original.jpg";
import derivative_blurred from "./img/fourier-derivative-blurred.png";
import blurred from "./img/fourier-blurred.png";
import transformed_mask from "./img/fourier-transformed-mask.png";
import lowpassed_transform from "./img/fourier-lowpassed-transform.png";
import final from "./img/fourier-final.png";

function FCI() {
  return (
    <div>
      <div className="Project">
        <div className="ProjectTitle">
          <h1>Fourier Transform Color Isolation POC</h1>
          <p>
            isolating a center of a color block in an image using fourier
            transform
          </p>
        </div>
        <div className="ProjectSubHeading" id="overview">
          <h2 className="SectionHeading">Overview</h2>
          <p className="SectionDesc">
            This app leverages fourier transform's ability to blur an image, and
            sobel's ability to take a 2d derivative, in order to find the center
            of a color mask. This is probably not practical, but it's fun to see
            how it can work.
            <br />
            <br />I got this idea from a 3blue1brown video in which he talked
            about kernel blurring and fourier transform as an alternative. I
            thought it would be cool to apply this process to FTC possibly, but
            I never ended up coding it in java.
            <br />
            <br />
          </p>
          <Info title="fourier transform">
            An algorithm which takes some function and converts it into it's
            component waves. Expresses a function by its component frequencies.
          </Info>
          <Info title="sobel">
            A form of kernel processing often used for edge detection.
          </Info>
        </div>
        <div className="ProjectSubHeading" id="software">
          <h2 className="SectionHeading">Software</h2>
          <p className="SectionDesc">
            This is all python, it uses some opencv for applying sobel, and
            numpy for the 2d discrete fft.
            <br />
            <br />
            There are three main classes, one for preparing the image (ImPrep),
            one for processing it (ImProc), and one for taking that and finding
            the center of the processed mask (ImMaxer).
            <br />
            <br />
            There are a bunch of ways to play with the parameters of functions
            and to mess with other parts of the system to get interesting
            results.
          </p>
        </div>
        <div className="ProjectSubHeading" id="playbyplay">
          <h2 className="SectionHeading">Play By Play</h2>
          <p className="SectionDesc">
            First, we take the original image and downscale it so it takes less
            time to process.
          </p>
          <img
            src={original}
            alt="rubber duck, yellow cube and a wiffle ball"
            className="ProjectImage"
          ></img>
          <p className="SectionDesc">
            Next, we turn it into a black image with a white mask of some
            specified HSV color range (this one looks for yellow).
          </p>
          <img
            src={masked}
            alt="all black with white where yellow was in the original"
            className="ProjectImage"
          ></img>
          <p className="SectionDesc">
            After that, we want to find the center of large groups of pixels. We
            accomplish that by applying a 2d discrete fourier transform to the
            image. This breaks down the 2d image into its sine/cosine component
            waves. The detail is represented by high frequency waves (on the
            outside), and the broader features are represented by lower
            frequency waves (near the middle).
            <br />
            <br />
            This is a visualization, the actual output is a complex number, so
            we are missing a whole part of the transform in this image.
          </p>
          <img
            src={transformed_mask}
            alt="fourier transform of masked"
            className="ProjectImage"
          ></img>
          <p className="SectionDesc">
            By cutting all the high frequency waves out of the fourier
            transform, we are left with what is essentially a low passed image.
          </p>
          <img
            src={lowpassed_transform}
            alt="black with a circle of the fourier transform in the middle"
            className="ProjectImage"
          ></img>
          <p className="SectionDesc">
            We then apply an un-transform and find that by cutting the high
            frequencies (details) out of the image, we have blurred the image.
          </p>
          <img
            src={blurred}
            alt="the second blurred with some artifacts"
            className="ProjectImage"
          ></img>
          <p className="SectionDesc">
            Now we have a blur and want to find maximums. A great way I thought
            of doing this was imagining the grayscale image was a 3d function
            where the value of each pixel is the z-value. We can then conclude
            that maximums will be found at points when the plane's slope tangent
            to this point is equal to zero. This is a similar thing to finding a
            derivative of a 3d function (there may be a more technical term for
            this, but I haven't take multivaiable calc yet, so pardon me).
            Luckily, the sobel kernel does this for us. Next, we apply it to the
            blurred image.
          </p>
          <img
            src={derivative_blurred}
            alt="sobel applied to the previous"
            className="ProjectImage"
          ></img>
          <p className="SectionDesc">
            Now we can look through the derivative image and find where these
            zero points are when we have maximums. Those points are highlighted
            with a white dot.
          </p>
          <img
            src={final}
            alt="two dots where the yellow objects were on the first"
            className="ProjectImage"
          ></img>
          <hr />
          <h2>Conclusion</h2>
          <p>
            This was a fun thing to code, and it was great to think of ideas for
            how to get where I wanted and then apply them. I know that there are
            better ways to do this, but I'm proud of this method because I came
            up with it in a couple days without really reasearching other ways
            to do what I wanted.
            <br />
            <br />
            This thinking was original which is why I'm posting it here. I hope
            you also got something out of it, I think its really neat that this
            type of thing works, and that all the steps are fairly intuitive.
            <br />
            <br />
            Anyways, thanks for reading!
            <h4>- Daniel</h4>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FCI;
