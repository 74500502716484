import React, { useEffect } from "react";

export default function ScrollVideo(props) {
  const crop = {
    overflow: "hidden",
  };
  const uuid = crypto.randomUUID();
  useEffect(() => {
    setInterval(() => {
      const video = document.getElementById(uuid);
      video.currentTime =
        (video.getBoundingClientRect().y / window.screen.height) *
          (props.end - props.start) +
        props.start
          ? ((window.screen.height - video.getBoundingClientRect().y) /
              window.screen.height) *
              (props.end - props.start) +
            props.start
          : 0;
    }, 100);
  }, []);
  return (
    <div className="ScrollVideo">
      <video
        src={props.src}
        style={{
          width: "100%",
        }}
        id={uuid}
      ></video>
    </div>
  );
}
