import React, { useEffect } from "react";
import "../css/Page.css";
import "../css/Contact.css";

function Contact() {
  return (
    <div>
      <div className="Page">
        <div className="PageContent">
          <div className="ContactMethod">
            <h1>E-Mail</h1>
            <p>danielduartebaird@gmail.com</p>
            <p>daniel.duarte-baird@wheelergmail.org</p>
          </div>

          <div className="ContactMethod">
            <h1>Github</h1>
            <p>@danieldb</p>
          </div>

          <div className="ContactMethod">
            <h1>LinkedIn</h1>
            <p>
              Daniel Duarte-Baird (99% sure im the only person with this name)
            </p>
          </div>

          <h1></h1>
        </div>
      </div>
    </div>
  );
}

export default Contact;
