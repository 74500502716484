import React, { useEffect } from "react";
import "../css/ProjectTab.css";
import "../css/Home.css";

let fireworks = [];
let gone = false;

function Fireworks(props) {
  /*  
    firework = {
        color: {
            r: int
            g: int
            b: int
        },
        popHeight: int, 
        popSize: int,
        popDensity: int
        popTime: 
        ticks: 
    }
    */
  // react effect to set reoccuring function to print to console isOn every second
  useEffect(() => {
    document.addEventListener("makefirework", makeFirework, false);

    setTimeout(go, 1000);
  }, []);

  return (
    <div className="Fireworks">
      <canvas
        id="fireworks"
        style={{ position: "fixed" }}
        height={window.innerHeight}
        width={window.innerWidth}
        className="FireworkCanvas"
      ></canvas>
    </div>
  );
  function makeFirework(n) {
    if (fireworks.length < 6) {
      for (let i = 0; i < 1; i++) {
        fireworks.push(
          createFirework(
            Math.random() * window.innerWidth,
            [Math.random() * 360, 100, 50],
            Math.random() *
              (window.innerHeight -
                document.getElementById("nav").clientHeight -
                100),
            10,
            Math.random() * 30,
            Math.random() + 8
          )
        );
      }
    }
  }
  function go() {
    if (gone) return;
    gone = true;

    fireworks = [
      ...fireworks,
      createFirework(
        Math.random() * window.innerWidth,
        [Math.random() * 360, 100, 50],
        Math.random() *
          (window.innerHeight -
            document.getElementById("nav").clientHeight -
            100),
        10,
        Math.random() * 30,
        Math.random() + 8
      ),
    ];
    fireworks.push(
      createFirework(
        Math.random() * window.innerWidth,
        [Math.random() * 360, 100, 50],
        Math.random() *
          (window.innerHeight -
            document.getElementById("nav").clientHeight -
            100),
        10,
        Math.random() * 30,
        Math.random() + 8
      )
    );
    fireworks.push(
      createFirework(
        Math.random() * window.innerWidth,
        [Math.random() * 360, 100, 50],
        Math.random() *
          (window.innerHeight -
            document.getElementById("nav").clientHeight -
            100),
        10,
        Math.random() * 30,
        Math.random() + 8
      )
    );

    let can = document.getElementById("fireworks");
    let ctx = can.getContext("2d");
    setInterval(tick, 17, ctx, can);
  }
  function tick(ctx, can) {
    ctx.clearRect(0, 0, can.width, can.height);
    for (let i in fireworks) {
      let f = fireworks[i];
      fireworks[i].y -= f.speed;
      ctx.fillStyle = `hsl(${f.color.h}, ${f.color.s}%, ${f.color.l}%)`;
      if (f.y < f.popHeight && f.popped === false) {
        for (let e = 0; e < f.popDensity; e++) {
          fireworks[i].pops.push(
            createPop(
              f,
              Math.random() * 2 - 1,
              Math.random() * 2 - 1,
              Math.random() * 3 + 2,
              Math.round(Math.random() * 20 + 200)
            )
          );
        }
        f.popped = true;
        continue;
      }
      if (f.popped) {
        for (let e in f.pops) {
          let p = f.pops[e];
          fireworks[i].pops[e].x += p.vector.x;
          fireworks[i].pops[e].y += p.vector.y;
          ctx.beginPath();
          ctx.arc(p.x, p.y, p.size, 0, 2 * Math.PI);
          ctx.fill();
          ctx.strokeStyle = `hsl(${f.color.h}, ${f.color.s}%, ${f.color.l}%)`;
          ctx.stroke();
          fireworks[i].pops[e].ticks++;
          fireworks[i].pops[e].size = (1 - p.ticks / p.lifetime) * p.ogSize;

          if (p.ticks > p.lifetime) fireworks[i].pops.splice(e, 1);
          if (fireworks[i].pops.length === 0) {
            fireworks.splice(i, 1);
            break;
          }
        }
        continue;
      }
      ctx.fillRect(f.x, f.y, 10, 20);

      fireworks[i].ticks++;
    }
  }

  function createFirework(pos, color, height, size, density, speed) {
    return {
      color: {
        h: color[0],
        s: color[1],
        l: color[2],
      },
      pops: [],
      popHeight: height,
      popSize: size,
      popDensity: density,
      speed: speed,
      ticks: 0,
      popped: false,
      x: pos,
      y: 1000,
    };
  }
  function createPop(firework, vecx, vecy, size, time) {
    return {
      x: firework.x,
      y: firework.y,
      vector: {
        x: vecx,
        y: vecy,
      },
      size: size,
      ogSize: size,
      lifetime: time,
      dead: false,
      ticks: 0,
    };
  }
}

export default Fireworks;
