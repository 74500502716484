import React from "react";
import "../../css/ProjectPage.css";
import wii1 from "./img/wii-packin1.jpg";
import wii2 from "./img/wii-packin2.jpg";
import wii3 from "./img/wii-packin3.jpg";

function WiiPackinProject() {
  return (
    <div>
      <div className="Project">
        <div className="ProjectTitle">
          <h1>Wii Packin'</h1>
          <p>a wii in a suitcase</p>
        </div>
        <div className="ProjectSubHeading" id="overview">
          <h2>Why is a portable wii necessary?</h2>
          <p>
            I have a wii, and I love it. I love playing wii sports, wii sports
            resort, mario kart, and other games. One thing that seemed lacking
            to me was the fact that I couldnt play these games on the go like,
            say, a nintendo switch. Last year I brough a wii in to school one
            day and it was extremely fun, so I thought, what if I packeged up a
            wii with the purpose of making it easy and convinient to bring with
            you (not to mention low-key ;]).
          </p>
          <div className="ImgContainer">
            <img src={wii3}></img>
          </div>
        </div>
        <div className="ProjectSubHeading" id="software">
          <h2>What does a portable wii need?</h2>
          <p>
            I started off asking what I would need from the wii, and here's what
            I came up with:
            <ul>
              <li>Wii console</li>
              <li>LCD Display</li>
              <li>Wii Sensor Bar</li>
              <li>
                Power Splitter (in order to only need to plug in one plug)
              </li>
              <li>Storage for Wii Remotes</li>
              <li>All the cords to connect these together</li>
            </ul>
            I would need to connect each of these in such a way that the cords
            were hidden, but I had easy access to the power cord, the wii disk
            slot, and the screen.
            <br />
            <br />I settled on putting the screen where you would open the
            suitcase, the wii disk slot on the side of the wii with a slot, and
            the power cord coming out of the back. All of the other cords were
            contained inside behind the monitor.
            <br />
            <br />
            Here is my finalized list of parts:
            <ul>
              <li>
                Wii Console - $0 (found on the side of the street a few years
                ago)
              </li>
              <li>Wii Cords/Splitter Cord - (I already had them)</li>
              <li>Wii Sensor Bar - (I already had one)</li>
              <li>Samsung Monitor - $10 (at yard sale)</li>
              <li>Special Samsung AV adapter - $10</li>
              <li>Suitcase - Free (from some facebook group my mom is on)</li>
              <li>
                Wood/Screws (for securly mounting wii/monitor) - 0$ (Thanks
                Brown Design Workshop)
              </li>
            </ul>
          </p>
        </div>
        <div className="ProjectSubHeading" id="hardware">
          <h2>Building!</h2>
          <p>
            This is where I admit that I mostly improvised on the design of this
            project. I had a general idea of what I wanted to do, but I didn't
            have a plan. Here were my steps:
            <ol>
              <li>
                Construct the monitor mount
                <ul>
                  <li>Take measurements of inside suitcase and monitor</li>
                  <li>
                    Cut wood of necessary dimensions to create a table-like
                    structure with an open center
                  </li>
                </ul>
              </li>
              <li>
                Mount in suitcase (don't put in monitor yet, you need to put a
                wii back there first)
              </li>
              <li>
                Create Wii bracket
                <ul>
                  <li>Measure Wii</li>
                  <li>
                    Cut wood of necessary dimensions to create a bracket that
                    will hold the wii in place in all dimensions
                  </li>
                  <li>Put wii in bracket and hot glue the wood together</li>
                </ul>
              </li>
              <li>Mount in suitcase (using a freaking ton of hot glue)</li>
              <li>Cut hole in suitcase for wii access</li>
              <li>Plug cords into everything</li>
              <li>Mount wii sensor bar to the top of the monitor (hot glue)</li>
              <li>Mount monitor on front</li>
              <li>Cut hole for power cord</li>
              <li>PLAY!</li>
              <div className="ImgContainer">
                <img src={wii2}></img>
                <img src={wii1}></img>
              </div>
            </ol>
          </p>
          <div className="ProjectSubHeading" id="">
            <hr />
            <h2>Conclusion</h2>
            <p>
              I am happy with this project, and probably wont make any
              improvements in the future. The only thing I might do is add
              zippers to the power cord/wii flaps to avoid cords falling out or
              other tomfoolery. During this process, I learned that winging a
              project that is low stakes can be really, REALLY fun. I also
              learned how unreasonably strong hot glue is when bonding wood. I
              look forward to the fun that can be had with my new invention :).
            </p>
          </div>
          <br />
          'Till next time, daniel
        </div>
      </div>
    </div>
  );
}

export default WiiPackinProject;
