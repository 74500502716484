import React from "react";
import "../../css/ProjectPage.css";
import pcb_img from "./img/wccs-pcb.jpg";
import nrf_img from "./img/wccs-nrf.jpg";

function WCCS() {
  return (
    <div>
      <div className="Project">
        <div className="ProjectTitle">
          <h1>Wireless Calculator Communication System</h1>
          <p>
            a means of wirelessly communicating between two ti-nspire
            calculators
          </p>
        </div>
        <div className="ProjectSubHeading" id="overview">
          <h2 className="SectionHeading">Overview</h2>
          <p className="SectionDesc">
            This app is written in lua, and makes use of the ti-nspire serial
            library to transmit data to an arduino. The arduino takes that data,
            formats it in a specific way, then relays it to another calculator
            (if any are listening).
          </p>
        </div>
        <div className="ProjectSubHeading" id="software">
          <h2 className="SectionHeading">Software</h2>
          <p className="SectionDesc">
            The software on the calculator leverages the lua serial library
            provided by texas instruments. This takes what the arduino sends and
            displays it to the screen, as well as telling the aruino what to
            broadcast.
            <br />
            <br />
            The arduino code is similar, it uses the NRF24L01 library to switch
            between sending and receiving modes in order to communicate with
            other calculators on the same wavelength.
            <br />
            <br />
            Currently, this is mostly a proof of concept, but if I was to add
            more features, here's what they would look like:
          </p>
          <ul>
            <li>
              Ability to address messages to different NRF channels (allowing
              for phone number type messaging where each calculator recieves
              some frequency)
            </li>
            <br />
            <li>
              Encryption, this would add a level of security to the messages,
              for anyone with a radio reciever could listen in right now.
            </li>
          </ul>
        </div>
        <div className="ProjectSubHeading" id="hardware">
          <h2 className="SectionHeading">Hardware</h2>
          <p className="SectionDesc">
            The arduino half of the hardware is fairly simple, simply an arduino
            (doesn't really matter which model) connected to a NRF24L01 module
            over SPI. This is easy to hook up to the calculator emulator on my
            computer through usb, but using a real calculator with it is a bit
            more of a challenge.
            <br />
            <br />
            Huge thanks to <a href="github.com/tangrs">tangrs</a> for some of
            the pcb measurements and designs from his original{" "}
            <a href="https://store.tangrs.id.au/nspire-serial.html">
              Ti-Nspire Serial Adapter
            </a>{" "}
            (now discontinued). This system wires the arduino to the back pins
            of the calculator in order to reach its serial port. One importatn
            thing is logic level conversion, the calculator runs at 3.3v, and
            some arduinos run at 5v. This would literally fry the calculator if
            messed up, so it's important to be careful.
          </p>
          <img
            src={pcb_img}
            className="ProjectImage"
            alt="green pcb with contacts that align with the back pins of the calculator"
          />
          <img
            src={nrf_img}
            className="ProjectImage"
            alt="arduino connected to NRF24L01 radio module"
          ></img>
          <p>
            I haven't connected up all the hardware yet because I haven't wanted
            to order some contact components and learn to do smd soldering. I
            also got caught up in other projects, so it may be a while before I
            try it with two real calculators. I have tried it with two emulators
            and it worked like a charm, so I expect it would work when wired up
            correctly.
          </p>
          <h2>Conclusion</h2>
          <p>
            I liked working on this project. I got to dabble in pdb design, and
            make my own sort of protocol for the communication. I learned lua
            (not a particularyly difficult or practical language) from it, and I
            learned how to write more sophistocated apps for my calculator.
            <br />
            <br />
            I'm not sure if I'll ever totally finish it, but I'm happy with how
            it turned out even not having wired everything up.
            <br />
            <br />
            Adios, thanks for reading!
            <h4>- Daniel</h4>
          </p>
        </div>
      </div>
    </div>
  );
}

export default WCCS;
