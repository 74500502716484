import React from "react";
import "../../css/ProjectPage.css";
import app_window from "./img/vrum-window.png";
function VrumMachine() {
  return (
    <div>
      <div className="Project">
        <div className="ProjectTitle">
          <h1>Vrum Machine</h1>
          <p>
            mini drum sequencer in vlang (download{" "}
            <a
              className="Link"
              href="https://github.com/danieldb/vrum-machine/releases/download/v1.0/sequencer"
            >
              here
            </a>
            )
          </p>
        </div>
        <div className="ProjectSubHeading" id="overview">
          <h2 className="SectionHeading">Overview</h2>
          <p className="SectionDesc">
            This is just a basic little sequencer I made in order to get to know
            vlang better. Theres a bunch of parameters to change around in code,
            and a not so accurate timing system that can drag sometimes. Overall
            though, its a fun app and a really minimal sequencer which is nice.
          </p>
          <img
            style={{ width: "100%", borderRadius: 8 }}
            src={app_window}
          ></img>
        </div>
        <div className="ProjectSubHeading" id="software">
          <h3>Software</h3>
          <p>
            V, or vlang was a relatively new language to me at the time I made
            this project. I had seen it when browsing github and was like "that
            sounds cool, i'm gonna try it!" This project served as a way for me
            to get to know v and it's quirks and features. I found it difficult
            to manage many parts of the code with no global state variables, but
            it got me to think in different ways which is good.
            <br />
            <br />I set up the code fairly dynamically, almost everything is
            calculated from other variables, meaning that if you want to change
            it, it is very easy.
          </p>
          <hr />
        </div>
        <div className="ProjectSubHeading" id="hardware">
          <h3>Conclusion</h3>
          <p>
            This was a relatively small project, and I might take this page down
            after I do some bigger things, but I really like how minimal and
            lightweight the whole app is. It is very boot and play, where there
            is no configuration beforehand and you just hop right into it when
            it is launched.
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default VrumMachine;
