import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Blog from "./pages/blog";
import About from "./pages/about";
import Projects from "./pages/projects";
import NavBar from "./components/NavBar";
import "./css/App.css";
import "./css/About.css";
import "./css/Blog.css";
import "./css/Projects.css";
import "./css/NavBar.css";
import "./css/About.css";
import Fireworks from "./components/Fireworks";
import WCCS from "./pages/Projects/wccs";
import FCI from "./pages/Projects/fourier-color-isolation";
import Waves from "./pages/WebProjects/waves";
import BeganWeatherstation from "./pages/Posts/began-weatherstation";
import ContinuedWeatherstation from "./pages/Posts/continued-weatherstation";
import VrumMachine from "./pages/Projects/vrum-machine";
import VPNG from "./pages/Projects/vpng";
import Contact from "./pages/contact";
import WiiPackin from "./pages/Posts/wii-packin";
import WiiPackinProject from "./pages/Projects/wii-packin";

function App() {
  return (
    <>
      <img
        src={
          "https://uploads-ssl.webflow.com/5f187058af48a660d3a65b2c/5f2230012a94117c07dfdc45_providence_orange.png"
        }
        style={{
          position: "fixed",
          bottom: "-440px",
          right: "-2200px",
          zIndex: "-1",
          filter: "grayscale(100%) opacity(10%)"
        }}
      ></img>
      <NavBar />
      <Fireworks></Fireworks>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route
            path="/blog/began-weatherstation"
            exact
            element={<BeganWeatherstation />}
          />
          <Route path="/blog/wii-packin" exact element={<WiiPackin />} />
          <Route
            path="/blog/continued-weatherstation"
            exact
            element={<ContinuedWeatherstation />}
          />
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/projects/wccs" exact element={<WCCS />} />
          <Route path="/projects/waves" exact element={<Waves />} />
          <Route
            path="/projects/fourier-color-isolation"
            exact
            element={<FCI />}
          />
          <Route
            path="/projects/vrum-machine"
            exact
            element={<VrumMachine />}
          />
          <Route
            path="/projects/wii-packin"
            exact
            element={<WiiPackinProject />}
          />
          <Route path="/projects/vpng" exact element={<VPNG />} />
        </Routes>
      </Router>
      <div id="master_footer"></div>
    </>
  );
}

export default App;
