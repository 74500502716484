import React from "react";
import PostPage from "../../components/PostPage";
import "../../css/PostPage.css";
import "../../css/ProjectPage.css";
import img from "./img/wii-packin.jpeg";

function WiiPackin() {
  return (
    <div>
      <PostPage
        img={img}
        title="Begun work on Weatherstation Project"
        date="9/16/22"
        desc="started building anemometer from incremental encoder"
      >
        <p>
          On 11/17/2022, I made a thing called the Wii Packin'. It is
          essentially a wii inside a suitcase.
          <br />
          <br />
          The idea began in my head. About 6 months ago, I was wondering if it
          would be possible would make a portable wii, like a switch but for wii
          games. It culminated in the ideation of a wii in a suitcase with a
          monitor on the front.
          <br />
          <br />
          The idea of the wii packin' came to reality about last week when I got
          a suitcase that fit the onitor perfectly, and ordered the necessary
          adapters to conect the wii to my $10 monitor.
          <br />
          <br />
          That night, I went to the Brown Design Workshop and for 2.5 hours I
          cut a plywood frame and bracket for the monitor and wii to put in the
          suitcase. After using an unholy volume of hot glue, the build came
          together. I plugged in all the cables and turned it on. Unfortunately,
          at the time I had no wii remotes with batteries, so I brought it home
          and started playing.
          <br />
          <br />
          This was such a fun project because of how easy it was to bring
          something in my mind into reality. I practically was winging it the
          entire time because I knew a rough idea of what I was trying to do,
          but knew making plans would take more time than they would be worth.
          <br />
          This project has opened my eyes to other possibilities like the X-Box
          or PlayStation packin' or other devices I could make portable. Also,
          putting a battery inside would make it way better, but I dont have the
          money for a battery big enough to power it for a reasonable amount of
          time.
          <br />
          <br />
          <br />
          <br />
          Have a great day!
          <h2>- Daniel</h2>
        </p>
      </PostPage>
    </div>
  );
}

export default WiiPackin;
