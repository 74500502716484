import React from "react";
import "../css/Info.css";

function Info(props) {
  return (
    <div className="Info">
      <h1>{props.title}</h1>
      <p>{props.children}</p>
    </div>
  );
}

export default Info;
