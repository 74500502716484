import React, { useEffect } from "react";
import { randInt } from "three/src/math/MathUtils";
import ScrollVideo from "../components/ScrollVideo";
import surf from "./img/crop_surf.mp4";
import skim from "./img/crop_skim.mp4";
import ftc from "./img/ftc.jpg";
function About() {
  return (
    <div>
      <div className="About">
        <div className="PageContent">
          <div className="AboutTitle">
            <h1>About</h1>
          </div>
          <div className="Blurbs">
            <div className="AboutBlurb right">
              <p>
                I am a student at The Wheeler School in Providence, Rhode Island
              </p>
            </div>
            <div className="AboutBlurb left">
              <p>I love to surf</p>
              <ScrollVideo
                end={7}
                start={-7}
                height={200}
                src={surf}
              ></ScrollVideo>
            </div>
            <div className="AboutBlurb rows">
              <div>
                <p className="right">
                  I'm interested in computer science (duh) as well as other
                  branches of STEM
                </p>
              </div>
            </div>
            <div className="AboutBlurb rows">
              <img src={ftc} className="left Image"></img>
            </div>
            <div className="AboutBlurb rows">
              <div>
                <p>
                  I participate in First Tech Challenge robotics competition on
                  team #252, the electric quahogs (
                  <a
                    className="Link"
                    href="https://electricquahogs.gatsbyjs.io"
                  >
                    check us out here
                  </a>
                  )
                </p>
              </div>
            </div>
            <div className="AboutBlurb left">
              <p>I like to skimboard</p>
              <ScrollVideo
                end={10}
                start={3}
                height={200}
                src={skim}
              ></ScrollVideo>
            </div>
            <div className="AboutBlurb right">
              <p>I am a musician, I like jazz</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
