import React from "react";
import ProjectTab from "../components/ProjectTab";
import "../css/Projects.css";

function Projects() {
  return (
    <div>
      <div className="Projects">
        <h1 className="ProjectsTitle">Projects</h1>

        <ProjectTab
          date="November 17, 2022"
          name="wii packin'"
          github=""
          skills="wii build portable-tech"
          desc="a wii in a suitcase"
          link="wii-packin"
        />
        <ProjectTab
          date="June 28, 2022"
          name="vpng"
          github="vpng"
          skills="v images hex"
          desc="a png binary parser written in the v language"
          link="vpng"
        />
        <ProjectTab
          date="May 20, 2022"
          name="v drum machine"
          github="vrum-machine"
          skills="v music"
          desc="a basic drum sequencer written in v"
          link="vrum-machine"
        />
        <ProjectTab
          date="April, 2022"
          name="wireless calculator communication system"
          github="wccs"
          skills="lua ti-nspire pcb arduino nrf"
          desc="a means of communicating wirelessly between two ti-nspire calculators"
          link="wccs"
        />
        <ProjectTab
          date="February, 2022"
          name="fourier color isolation"
          github="FourierColorIsolation-POC"
          skills="fourier-transform ftc images"
          desc="a proof of concept in finding the center of some color mask using a 2d-discrete fft"
          link="fourier-color-isolation"
        />
      </div>
    </div>
  );
}

export default Projects;
