import React from "react";
import "../css/Home.css";

function Home() {
  const [agePrecision, setAgePrecision] = React.useState(0);
  const [now, setNow] = React.useState(new Date());
  const [age, setAge] = React.useState(
    Math.floor(
      ((now.getTime() - new Date(2006, 11, 17, 14, 6, 0).getTime()) /
        1000 /
        60 /
        60 /
        24 /
        365.25) *
        Math.pow(10, agePrecision)
    ) / Math.pow(10, agePrecision)
  );
  let updateAge = () => {
    setAge(
      Math.floor(
        ((new Date().getTime() - new Date(2006, 11, 17, 14, 6, 0).getTime()) /
          1000 /
          60 /
          60 /
          24 /
          365.25) *
          Math.pow(10, agePrecision)
      ) / Math.pow(10, agePrecision)
    );
  };
  React.useEffect(() => {
    setInterval(() => {
      setNow(new Date());
    }, 1000);
  });

  //React.useEffect(updateAge, [agePrecision]);
  React.useEffect(updateAge, [now]);
  return (
    <div className="snowflake">
      <div className="Home">
        <h1 style={{ gridRow: 1 }}>hi! i'm daniel,</h1>
        <p className="left" style={{ gridRow: 2 }}>
          i'm a{" "}
          <button
            onClick={() => {
              setAgePrecision(agePrecision + 1);
              if (agePrecision > 7) setAgePrecision(0);
              updateAge();
            }}
          >
            {((age * Math.pow(10, agePrecision)) / 10) % 1 === 0
              ? age + "0"
              : age}
          </button>{" "}
          year old software engineer and fun enthusiast from Rhode Island.
        </p>
        <p className="right" style={{ gridRow: 3 }}>
          i'm well versed in javascript, python, and java and occasionally use
          v, c++, and lua.{" "}
        </p>
        <p className="center" style={{ gridRow: 4 }}>
          here's a link to my favorite project of mine <br />{" "}
          <button
            onClick={() => {
              window.open("https://github.com/danieldb/vpng", "_blank");
            }}
          >
            <span role="img" aria-label="celebration">
              🎉 🥳 ✨
            </span>
          </button>
        </p>
        <p className="left" style={{ gridRow: 5 }}>
          click for my favorite of someone else's project <br />{" "}
          <button
            onClick={() => {
              window.open("https://hopewaves.app/", "_blank");
            }}
          >
            <span role="img" aria-label="celebration">
              🌎 💻 🤌 🤖
            </span>
          </button>
        </p>

        <p className="right" style={{ gridRow: 6 }}>
          here's a link to a freaking awesome playlist <br />{" "}
          <button
            onClick={() => {
              window.open(
                "https://open.spotify.com/playlist/6VWMuZtVDhng2ynvf2AVda?si=9434b2992d804cbd",
                "_blank"
              );
            }}
          >
            <span role="img" aria-label="celebration">
              🎺 🎷
            </span>
          </button>
        </p>
      </div>
    </div>
  );
}

export default Home;
