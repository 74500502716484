import React from "react";
import "../../css/ProjectPage.css";

function VPNG() {
  return (
    <div>
      <div className="Project">
        <div className="ProjectTitle">
          <h1>vpng</h1>
          <p>png parser written in vlang</p>
        </div>
        <div className="ProjectSubHeading" id="overview">
          <h2 className="SectionHeading">Overview</h2>
          <p className="SectionDesc">
            This was a project for me to learn more about vlang, but also to
            learn the png standard. It was really <i>interesting</i> learning a
            fileformat, and looking at a webpage from 1996 trying to figure out
            why my code didn't work. All in all, it was a good time, and I had
            never tried something like it before. Who knows, I might even make
            another parser for another filetype in the future (FITS maybe,
            hmm...).
          </p>
        </div>
        <div className="ProjectSubHeading" id="software">
          <h2>More commentary on this project coming soon!</h2>
        </div>
      </div>
    </div>
  );
}

export default VPNG;
