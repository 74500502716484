import React from "react";
import "../css/Post.css";

function Post(props) {
  return (
    <div className="Post">
      <div className="PostTitle">
        <h1>{props.title}</h1>
        <h4>
          {props.desc} ⸱ {props.date}
        </h4>
      </div>
      <img src={props.img} alt={props.title}></img>
      <div className="PostIntro">
        <p>{props.intro}</p>
        <a className="PostLink" href={"blog/" + props.linkname}>
          read more...
        </a>
      </div>
    </div>
  );
}

export default Post;
