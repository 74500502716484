import React from "react";
import "../css/PostPage.css";

function PostPage(props) {
  // react effect to set reoccuring function to print to console isOn every second
  return (
    <div class="PostPage">
      <div className="PostArea">
        <div className="PostPageTitle">
          <h1>{props.title}</h1>
          <h4>
            {props.desc} ⸱ {props.date}
          </h4>
        </div>
        <img src={props.img} alt={props.title}></img>
        <div className="PostPageBody">{props.children}</div>
      </div>
    </div>
  );
}

export default PostPage;
