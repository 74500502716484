import React from "react";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import "../css/PostPage.css";

function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React__default = _interopDefault(React);

function Stl(
  width,
  height,
  url,
  objectColor,
  gridLineColor,
  skyboxColor,
  groundColor,
  lightColor,
  div_id
) {
  var camera, cameraTarget, scene, renderer, controls;
  scene = new THREE.Scene();
  scene.background = null;
  camera = new THREE.PerspectiveCamera(45, width / height, 1, 100000);
  camera.position.set(200, 100, 200);
  cameraTarget = new THREE.Vector3(0, 0, 0);
  renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    preserveDrawingBuffer: true,
  });
  renderer.setClearColor(0x000000, 0);
  renderer.domElement.classList.add("stlviewer");
  renderer.setSize(width, height);
  document.getElementById(div_id).innerHTML = "";
  document.getElementById(div_id).appendChild(renderer.domElement);
  controls = new OrbitControls(camera, renderer.domElement);
  controls.target.set(0, 0, 0);
  controls.update();
  var hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
  hemiLight.position.set(0, 200, 0);
  scene.add(hemiLight);
  var directionalLight = new THREE.DirectionalLight(lightColor);
  directionalLight.position.set(0, 200, 100);
  directionalLight.castShadow = true;
  directionalLight.shadow.camera.top = 180;
  directionalLight.shadow.camera.bottom = -100;
  directionalLight.shadow.camera.left = -120;
  directionalLight.shadow.camera.right = 120;
  scene.add(directionalLight);
  var loader = new STLLoader();
  loader.load(url, function (geometry) {
    var material = new THREE.MeshPhongMaterial({
      color: objectColor,
      specular: 0x111111,
      shininess: 200,
    });
    var mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0, 0);
    mesh.rotation.set(-Math.PI / 2, 0, 0);
    mesh.scale.set(1, 1, 1);
    mesh.castShadow = true;
    mesh.receiveShadow = true;

    var position = geometry.attributes.position;
    var faces = position.count / 3;
    var p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3();

    for (var i = 0; i < faces; i++) {
      p1.fromBufferAttribute(position, i * 3 + 0);
      p2.fromBufferAttribute(position, i * 3 + 1);
      p3.fromBufferAttribute(position, i * 3 + 2);
    }

    scene.add(mesh);
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.shadowMap.enabled = true;

  var animate = function animate() {
    try {
      if (document.body.classList.contains("darkmode")) {
        if (scene.children[2].material.color.getHex() !== 0x111111) {
          scene.children[2].material.color.setHex(0x111111);
        }
      } else {
        if (scene.children[2].material.color.getHex() !== 0x555555) {
          scene.children[2].material.color.setHex(0x555555);
        }
      }
    } catch (e) {
      console.log(e);
    }
    requestAnimationFrame(animate);
    render();
  };

  var render = function render() {
    camera.lookAt(cameraTarget);
    renderer.render(scene, camera);
  };

  animate();
}

var StlViewer = function StlViewer(_ref) {
  var width = _ref.width,
    height = _ref.height,
    url = _ref.url,
    objectColor = _ref.objectColor,
    gridLineColor = _ref.gridLineColor,
    skyboxColor = _ref.skyboxColor,
    groundColor = _ref.groundColor,
    lightColor = _ref.lightColor;
  const div_id = crypto.randomUUID();
  React.useEffect(
    function () {
      Stl(
        width,
        height,
        url,
        objectColor,
        gridLineColor,
        skyboxColor,
        groundColor,
        lightColor,
        div_id
      );
    },
    [url]
  );
  return React__default.createElement("div", {
    id: div_id,
  });
};

export { StlViewer };
