import React from "react";
import csv from "csv";
import Info from "../../components/Info";
import "../../css/ProjectPage.css";

function Waves() {
  csv.parse(new File("44097h2021.csv", "w").text, function(err, data) {
    console.log(data);
  });
  return <div></div>;
}

export default Waves;
