import React from "react";
import PostPage from "../../components/PostPage";
import "../../css/PostPage.css";
import "../../css/ProjectPage.css";
import BMP_DHT from "./img/DHT-BMP-circuit-board.jpeg";
import img from "./img/DHT-BMP-circuit-board.jpeg";
import anemometer_top from "./img/anemometer-top.jpeg";
import anemometer_pipe from "./img/anemometer-pipe.jpeg";

function ContinuedWeatherstation() {
  return (
    <div>
      <PostPage
        img={img}
        title="Begun work on Weatherstation Project"
        date="9/16/22"
        desc="started building anemometer from incremental encoder"
      >
        <p>
          A few weeks ago I started my weatherstation project. I have made a lot
          of progress since then and am going to share that here!
          <br />
          <br />I ended last post with the promise of finishing 3d printing the
          anemometer, which I have done (yay!). I also ordered and recieved the
          DHT22 temperature and humidity sensor, as well as the BMP180
          atmospheric pressure sensor. I even dug a water level sensor out from
          an old sensor kit I had in my cabinet and plan on using it for the
          rain gauge.
          <br />
          <br />
          <h3>General Plans</h3>I plan to keep each part of the station in
          modules, this will make wire management easier and layout for the
          final design easier. I plan on giving each module a circuit board with
          screw terminals for each wire needed. This will make it easier to
          route everything to the master board without worrying about the hassle
          it will be to desolder wires if they break.
          <br />
          <br />
          <h3>DHT22/BMP180</h3>
          For these sensors, I plan on using a small inverted plastic box to
          enclose them. I want the box to ideally be as thin as possible to
          prevent insulation but still protect them from rain an snow. One of
          the toughest parts of figuring out a good way to enclose everything in
          this project is the fact that it cannot be susceptible to
          rain/snow/ice but still needs to read actual weather conditions, so
          safeguards can't alter those.
          <br />
          <br />I also made a small circuit board to connect the sensors to the
          arduino, it looks like this:
          <br />
          <br />
          <img src={BMP_DHT} alt="BMP and DHT circuit board"></img>
          <br />
          <br />
          <h3>Anemometer</h3>I have finished 3d printing the anemometer as
          promised, and have mounted it on a pvc pipe with a setscrew holding it
          in place. Here are some (unapologetically huge) pictures:
          <br />
          <br />
          <img
            src={anemometer_pipe}
            alt="Anemometer encoder mounted in pipe"
          ></img>
          <img src={anemometer_top} alt="Anemometer encoder"></img>
          (btw: the anemometer has one orange cup on it because the other purple
          one broke and I printed the orange one at the Brown Design Workhshop
          (BDW) instead of Wheeler. It is PLA while the others are PETG, but the
          slight mass difference hasn't seemed to be an issue.)
          <br />
          <br />
          <h3>Software!</h3>I finished all the (backend) software for the
          weather station. I have a program on the arduino to send sensor data
          over UART to the raspberry pi. The raspberry pi recieves the data with
          a python script then sends it to an AWS DynamoDB table. I also have
          options to send the data to a local csv file or postgresql database,
          but DynamoDB is the most robust. You can find all this code on my
          github <a href="https://github.com/danieldb/weather-station">here</a>.
          <h3>Next Steps</h3>I still have to build/design the rain gauge and the
          wind vane, as well as the compartment for the
          humidiy/temperature/pressure sensor board. I also need to put
          everything together into a cohesive unit.
          <br />
          <br />
          Clearly, there is more work to be done, and not just on hardware. I
          plan on making an ios app and web app to show current and past
          readings from the station. I think this will help me learn more swift
          and break into that part of coding.
          <br />
          <br />
          <br />
          <br />
          Gracias!
          <h2>- Daniel</h2>
        </p>
      </PostPage>
    </div>
  );
}

export default ContinuedWeatherstation;
