import React from "react";
import PostPage from "../../components/PostPage";
import "../../css/PostPage.css";
import "../../css/ProjectPage.css";
import img from "./img/began-weatherstation.jpg";
import prototype from "./img/cardboard prototype.jpg";
import { StlViewer } from "../../components/StlView";
import full_anemometer from "./STLs/Tri-Hemisphere Anenometer v1.stl";
import hub from "./STLs/Anemometer Hub v2.stl";
import cupv2 from "./STLs/Anemometer Cup v3.stl";

function BeganWeatherstation() {
  return (
    <div>
      <PostPage
        img={img}
        title="Begun work on Weatherstation Project"
        date="9/16/22"
        desc="started building anemometer from incremental encoder"
      >
        <p>
          A couple days ago I started work on what should be a project that will
          occupy me for the next few weeks. I plan on building and coding a
          weatherstation with a bouquet of interconnected weather related
          sensors, all controlled by arduino and raspberry pi. This week I
          started on my anemometer design.
          <br />
          <br />I started by looking into what kind of sensors I could use to
          measure weather which were in reach for an amateur like me. I came up
          with this list of six:
          <ul>
            <li>
              Thermometer <i>(temperature)</i>
            </li>
            <li>
              Hygrometer <i>(humidity)</i>
            </li>
            <li>
              Barometer <i>(pressure)</i>
            </li>
            <li>
              Weather Vane<i>(wind speed)</i>
            </li>
            <li>
              Anemometer <i>(wind direction)</i>
            </li>
            <li>
              Rain Gague <i>(rainfall)</i>
            </li>
          </ul>
          I made a list of my ideas of how to make/implement the sensors and
          some things to consider. I will post about the other sensors as I make
          them, but here's the anemometer.
          <br />
          <h3>Anemometer</h3>
          <i>wind speed</i>
          <br />
          Things to consider:
          <ul style={{ listStyleType: "disc" }}>
            <li>need hemispheres </li>
            <li>need rods to hold them </li>
            <li>need something to measure </li>
            <li>cant let snow clog or ice affect </li>
          </ul>
          Ideas:
          <ul style={{ listStyleType: "disc" }}>
            <li>
              may need a heating system around it
              <ul style={{ listStyleType: "circle" }}>
                <li>only activate when below some threshold on temp sensor</li>
              </ul>
            </li>
            <li>can 3d print hemisphere cups </li>
            <li>can use thin aluminum axles for rods</li>
            <li>can use some motor encoder for measuring rotation</li>
          </ul>
          <hr />
          <h3>Design / CAD</h3>
          Next, I hopped into Fusion 360 to start sketching out my thoughts.
          Here's what came out of it:
          <br />
          <br />
          <StlViewer
            width={window.innerWidth > 600 ? 500 : window.innerWidth * 0.9}
            height={window.innerWidth > 600 ? 500 : window.innerWidth * 0.9}
            url={full_anemometer}
            // groundColor="rgba(0, 0, 0, 1)"
            objectColor="rgb(50, 50, 50)"
            skyboxColor="#ffff0000"
            // gridLineColor="rgb(0, 0, 0)"
            lightColor="rgb(255, 255, 255)"
          />
          <br />
          <br />
          Admittedly, it was not the finest work, but it was a solid start. I
          had a general idea of where I wanted to go with it.
          <br />
          <br />
          After thinking for a while I realized that I should probably make it a
          bit smaller and have the hub use D-shafts because that was what the
          encoder I bought (
          <a
            href="https://www.amazon.com/Aideepen-Encoder-5V-24V-Incremental-Rotary/dp/B01MZ4V1XP/"
            className="Link"
          >
            from here
          </a>
          ) used.
          <br />
          <br />
          Next, I made a hub for those D-shafts to fit into. This would plug
          into the motor and let 3 shafts stick out which I could secure cups on
          to catch wind.
          <br />
          <br />
          <StlViewer
            width={window.innerWidth > 600 ? 500 : window.innerWidth * 0.9}
            height={window.innerWidth > 600 ? 500 : window.innerWidth * 0.9}
            url={hub}
            // groundColor="rgba(0, 0, 0, 1)"
            objectColor="rgb(50, 50, 50)"
            skyboxColor="#ffff0000"
            // gridLineColor="rgb(0, 0, 0)"
            lightColor="rgb(255, 255, 255)"
          />
          <br />
          <br />I tried using D-shafts in a POC design with little paper cups
          and found they were cumbersome and it would probably be best just
          using all 3d-printed pieces with extremely short axles.
          <br />
          <br />
          I designed a cup which I am fairly satisfied with, but there does seem
          to be a point which could be prone to failure where the plastic shaft
          meets the cup.
          <br />
          <br />
          <StlViewer
            width={window.innerWidth > 600 ? 500 : window.innerWidth * 0.9}
            height={window.innerWidth > 600 ? 500 : window.innerWidth * 0.9}
            url={cupv2}
            // groundColor="rgba(0, 0, 0, 1)"
            objectColor="rgb(50, 50, 50)"
            skyboxColor="#ffff0000"
            // gridLineColor="rgb(0, 0, 0)"
            lightColor="rgb(255, 255, 255)"
          />
          <br />
          <br />
          Overall, I'm fairly happy with my design, I havent been able to print
          the cups yet because it's the weekend, but I plan to do so this week
          at school.
          <br />
          <br />
          <hr />
          <h3>I coded some stuff too</h3>
          Before designing a more sophistocated Anemometer, I made a quick
          cardboard fan I could use for code development. It looked like this:
          <br />
          <br /> (it's a bad angle because i didn't have my phone)
          <img src={prototype} alt="prototype encoder anemometer"></img>
          <br />
          <br />
          This let me point it at a fan and measure some data. I coded the
          arduino with a simple interrupt to add to a count which printed to
          serial and then reset every second. This pretty much tallied the ticks
          in each second.
          <br />
          <br />
          Overall, the code seems pretty easy. I'll have to calibrate it so that
          my units are correct rather than whatever ticks per second I'm getting
          right now, but that should be easy enough.
          <hr />
          <h3>Next Steps</h3>I think this was a good start on a project I'm
          quite excited for. The anemometer and weathervane are going to be the
          toughest pieces of the weather station to design because of how
          mechanical they both are, but I'm excited for that prospect.
          <br />
          <br />
          I'm going to use a DHT22 and BMP180 for temp/humidity/pressure
          sensors, so that should be straightforward.
          <br />
          <br />
          <br />
          <br />
          Goodbye!
          <h2>- Daniel</h2>
        </p>
      </PostPage>
    </div>
  );
}

export default BeganWeatherstation;
