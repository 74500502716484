import React from "react";
import Post from "../components/Post";
import "../css/Blog.css";
import "../css/Post.css";
import began_weatherstation from "./Posts/img/began-weatherstation.jpg";
import continued_weatherstation from "./Posts/img/DHT-BMP-circuit-board.jpeg";
import wii_packin from "./Posts/img/wii-packin.jpeg";

function Blog() {
  return (
    <div>
      <div className="Blog">
        <div className="BlogTitle">
          <h1>Blog</h1>
          <p>where I post smallish updates about what i'm up to</p>
        </div>
        <div className="PostContainer">
          <Post
            img={wii_packin}
            linkname="wii-packin"
            title="Wii in a suitcase"
            date="11/23/22"
            desc="it's pretty cool"
            intro="On 11/17/2022, I made a thing called the Wii Packin'. It is
            essentially a wii inside a suitcase."
          ></Post>
          <Post
            img={continued_weatherstation}
            linkname="continued-weatherstation"
            title="Coded software for Weatherstation Project"
            date="10/11/22"
            desc="continued building weather station software and sensors"
            intro="
            A few weeks ago I started my weatherstation project. I have made a lot
            of progress since then and am going to share that here!"
          ></Post>
          <Post
            img={began_weatherstation}
            linkname="began-weatherstation"
            title="Begun work on Weatherstation Project"
            date="9/16/22"
            desc="started building anemometer from incremental encoder"
            intro="A couple days ago I started work on what should be a project that will occupy me for the next few weeks. I plan on building and coding a weatherstation with a bouquet of interconnected weather related sensors, all controlled by arduino and raspberry pi. This week I started on my anemometer design."
          ></Post>
        </div>
      </div>
    </div>
  );
}

export default Blog;
